/*
 * Copyright (C) Yağızhan IŞIK - 2022.
 * This app is developed for internal use only and may not be exported, shared, edited or distributed under any circumstances.
 */

//
// APP-WIDE CONSTANTS
//



const APP_RELEASE_VERSION = 80;
const APP_RELEASE = "PROD";


const APP_VERSION_TEXT = "2.0.2";


const DEBUG_API_URL = 'http://10.8.0.1:5000';
let API_URL = 'https://api.tur17.com';
const LOCAL_DOMAINS = ["localhost", "127.0.0.1"];

if (LOCAL_DOMAINS.includes(window.location.hostname)){
	//console.info('Localhost detected. Set API Host to Development.')
	//API_URL = DEBUG_API_URL;
}


const APP_MIDI_BASE_BUILD = 5;
const APP_MIDI_BASE_SEED = "DEV";

const API_TIMEOUT = 10000; // after 10 sec view api error page

const API_HOST = API_URL;
const MONEY_CURRENCY = "TL";
const DO_NOT_SEND_TOKEN_DEV = false;
const NEW_TAG_NAME = "Yeni Etiket";
const API_TOKEN_STORAGE = localStorage.getItem('AppAccessToken');
const INSTALLMENTS_START_MONTH = 9;
const FIXED_TAX_RATE = 0.18;

//
//
//


let notificationTemplate = `<bx-inline-notification style="width: 100%; margin-bottom: 1.5rem; margin-right: 0" title="##NOTTITLE##" kind="error" subtitle="##NOTBODY##" role="alert" open=""></bx-inline-notification>`;
let notificationTemplateSuccess = `<bx-inline-notification style="width: 100%; margin-bottom: 1.5rem; margin-right: 0" title="##NOTTITLE##" kind="success" subtitle="##NOTBODY##" role="alert" open=""></bx-inline-notification>`;
let _loadingIconActive = false;




function resetPrefs() {
	let defaultPresetPrefs = {
		"defaultTaxRate": 0.18,
		"endOfTermWarningLastShownMonth": 5,
		"EOTWarningEnabled": true,
		"animationsEnabled": true,
		"crashReportingEnabled": true,
		"rowsPerPage": 10,
		"prefInit": true,
		"showTermReportWarningsInDashboard": true
	}

	let defaultTags = {
		"DRIVER": "Sürücü Maliyeti",
		"FUEL": "Yakıt Maliyeti",
		"VEHICLE": "Araç Maliyeti",
		"KURS": "Ekstra Kurs Geliri"
	}
	userPreferences = defaultPresetPrefs;
	apiJsonPost('storage/preferences', defaultPresetPrefs);
	apiJsonPost('storage/tags', defaultTags);
}

function httpGet(theUrl) {
	const xmlHttp = new XMLHttpRequest();
	xmlHttp.open("GET", theUrl, false);
	xmlHttp.send(null);
	return xmlHttp.responseText;
}

function httpGetWT(theUrl, token) {
	const xmlHttp = new XMLHttpRequest();
	xmlHttp.open("GET", theUrl, false); // false for synchronous request
	if (!DO_NOT_SEND_TOKEN_DEV) {
		xmlHttp.setRequestHeader('x-access-token', token);
	}
	
	xmlHttp.send(null);
	return xmlHttp.responseText;
}

function sendNewMailSet(type = "redir", mailType = "customAll", target, body) {
	if(type === "redir") {
		// Full Experience Mail
		localStorage.setItem('PageDataMail', JSON.stringify({
			mailType: mailType,
			target: target,
			body: body
		}))
		
	}

}

function httpPost(theUrl, body) {
	const xmlHttp = new XMLHttpRequest();
	xmlHttp.open("POST", theUrl, false); // false for synchronous request
	xmlHttp.send(body);
	return xmlHttp.responseText;
}
function httpPostJ(theUrl, body) {
	const xmlHttp = new XMLHttpRequest();
	xmlHttp.open("POST", theUrl, false); // false for synchronous request
	
		xmlHttp.setRequestHeader('Content-Type', 'application/json')
	
	xmlHttp.send(body);
	return xmlHttp.responseText;
}

function httpPostWT(theUrl, token, body) {
	const xmlHttp = new XMLHttpRequest();
	xmlHttp.open("POST", theUrl, false); // false for synchronous request
	if (!DO_NOT_SEND_TOKEN_DEV) {
		xmlHttp.setRequestHeader('x-access-token', token);
	}
	
	xmlHttp.setRequestHeader('Content-Type', 'application/json');
	xmlHttp.send(JSON.stringify(body));
	return xmlHttp.responseText;
}

function notificationCreator(title, body) {
	document.querySelector(".wc-form-nf").innerHTML = notificationTemplate.replace('##NOTTITLE##', title).replace('##NOTBODY##', body);
}

function notificationCreatorSuccess(title, body) {
	document.querySelector(".wc-form-nf").innerHTML = notificationTemplateSuccess.replace('##NOTTITLE##', title).replace('##NOTBODY##', body);
}

function apiGet(endpoint) {
	let rawResponse = JSON.parse(httpGetWT(API_HOST + "/" + endpoint, API_TOKEN_STORAGE));
	if (rawResponse.status && rawResponse.status !== 200) {
		// Houston, we have a problem
		if(document.querySelector(".wc-form-nf")) {
			document.querySelector(".wc-form-nf").innerHTML = notificationTemplate.replace('##NOTTITLE##', rawResponse.notification.header).replace('##NOTBODY##', rawResponse.notification.text);
		}
		return null;
	} else {
		return rawResponse;
	}
}

function apiJsonPost(endpoint, body) {
	let rawResponse = JSON.parse(httpPostWT(API_HOST + "/" + endpoint, API_TOKEN_STORAGE, body));
	if (rawResponse.status && rawResponse.status !== 200) {
		// Houston, we have a problem
		document.querySelector(".wc-form-nf").innerHTML = notificationTemplate.replace('##NOTTITLE##', rawResponse.notification.header).replace('##NOTBODY##', rawResponse.notification.text);
		return null;
	} else {
		return rawResponse;
	}
}

function calculateCapacityText(capacc, activeuser) {
	if (capacc === 0) {
		return `Kullanım Dışı (${activeuser} / ${capacc})`;
	}
	if (activeuser === 0) {
		return `Tamamen Boş / Önerilir (${activeuser} / ${capacc})`;
	}
	if (capacc === activeuser) {
		return `Tamamen Dolu (${activeuser} / ${capacc})`;
	}
	if (capacc < activeuser) {
		return `Şafaksal Durum (${activeuser} / ${capacc})`;
	}
	if ((capacc - activeuser) < 5) {
		return `Neredeyse Dolu (${activeuser} / ${capacc})`;
	}
	if ((capacc - activeuser) < 10) {
		return `Müsait (${activeuser} / ${capacc})`;
	}

	return `Müsait / Önerilir (${activeuser} / ${capacc})`;

}

function checkRequiredFields() {
	let resultSuccess = true;
	if (document.querySelector('bx-input')) {
		console.log('Found at least 1 text input');
		document.querySelectorAll('bx-input').forEach((elem) => {
			if (elem.getAttribute('data-required') === "yes" && elem.value === "" && !elem.value) {
				resultSuccess = false;
				notificationCreator('Bazı Alanlar Doldurulmamış', 'Lütfen eksik alanları gözden geçirin ve işlemi tekrar deneyin.');
				elem.shadowRoot.querySelector('.bx--text-input__field-wrapper').style.cssText = 'border: 2px solid #fa4d56;';
			} else {
				elem.shadowRoot.querySelector('.bx--text-input__field-wrapper').style.cssText = '';
			}
		});
	}
	if (document.querySelector('bx-number-input')) {
		console.log('Found at least 1 number input');
		document.querySelectorAll('bx-number-input').forEach((elem) => {
			console.log(elem.value);
			if (elem.getAttribute('data-required') === "yes" && elem.value === "" && !elem.value) {
				resultSuccess = false;
				notificationCreator('Bazı Alanlar Doldurulmamış', 'Lütfen eksik alanları gözden geçirin ve işlemi tekrar deneyin.');
				elem.shadowRoot.querySelector('.bx--number__input-wrapper').style.cssText = 'border: 2px solid #fa4d56;';
			} else {
				elem.shadowRoot.querySelector('.bx--number__input-wrapper').style.cssText = '';
			}
		});
	}
	return resultSuccess;
}

function uuidv4() {
	return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c =>
		(c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16),
	);
}

const monthNamesInTurkish = ['Ocak', 'Şubat', 'Mart', 'Nisan', 'Mayıs', 'Haziran', 'Temmuz', 'Ağustos', 'Eylül', 'Ekim', 'Kasım', 'Aralık'];

function findMonth(monthID) {
	return monthNamesInTurkish[monthID - 1];
}

function basicCrashReport(rbody) {
	let crashReportID = uuidv4();
	crashReports.crashReports[crashReports.crashReports.length] = {id: crashReportID, element: rbody.CRElementID, reason: rbody.CRDetails.reportedReason};

	apiJsonPost('storage/CRI-' + crashReportID, rbody);
	apiJsonPost('storage/crashReports', crashReports);

	return crashReportID.toString();

}

function CRIncident(loggingType, elementID, reportedBy, incidentExplainText, incidentErrorCode, errorBody) {
	let templateJSON = {
		"CRVersion": 1,
		"CRLoggingType": loggingType,
		"CRElementID": elementID,
		"CRDetails": {
			"reportedReason": incidentErrorCode,
			"reportedReasonComment": incidentExplainText,
			"reportedBy": reportedBy,
			"reportedDate": +new Date(),
			"reportedVersion": APP_RELEASE_VERSION,
			"reportedRelease": APP_RELEASE,
		},
		"CRErrorBody": errorBody,
	};
	let crashReportID = "CR-DISABLED-NOT-REPORTED";
	if (userPreferences.crashReportingEnabled) {
		crashReportID = basicCrashReport(templateJSON);
	} else {
		console.warn('Crash not reported: Crash Reporting is disabled in user preferences.')
	}


	console.error('[CR] CRIncident generated. ID: ' + crashReportID);

	return crashReportID;
}

// const ErrorReporting = {
//     reportBackgroundIncident
// }

function silentCRF(abcd) {
	if (abcd.getAttribute('data-required') === "yes" && abcd.value === "" && !abcd.value) {

		//notificationCreator('Bazı Alanlar Doldurulmamış', 'Lütfen eksik alanları gözden geçirin ve işlemi tekrar deneyin.');
		abcd.shadowRoot.querySelector('.bx--text-input__field-wrapper').style.cssText = 'border: 2px solid #fa4d56;';
	} else {
		abcd.shadowRoot.querySelector('.bx--text-input__field-wrapper').style.cssText = '';
	}
}

function silentCRFN(abcd) {
	if (abcd.getAttribute('data-required') === "yes" && abcd.value === "" && !abcd.value) {

		//notificationCreator('Bazı Alanlar Doldurulmamış', 'Lütfen eksik alanları gözden geçirin ve işlemi tekrar deneyin.');
		abcd.shadowRoot.querySelector('.bx--number__input-wrapper').style.cssText = 'border: 2px solid #fa4d56;';
	} else {
		abcd.shadowRoot.querySelector('.bx--number__input-wrapper').style.cssText = '';
	}
}

function toggleLoadingAnimation() {
	console.log(+new Date());
	new Promise(() => {
		console.log(+new Date());
		if (!_loadingIconActive) {
			// Activate Loading
			document.querySelector('bx-inline-loading').classList.remove('dn');
			_loadingIconActive = true;
			console.log(+new Date());
		} else {
			document.querySelector('bx-inline-loading').classList.add('dn');
			_loadingIconActive = false;
			console.log(+new Date());
		}
	});

}

function toggleLoadingAnimationFast() {
	new Promise(() => {
		if (!_loadingIconActive) {
			// Activate Loading
			document.querySelector('bx-inline-loading').style.cssText = "opacity:1";
			_loadingIconActive = true;
		} else {
			document.querySelector('bx-inline-loading').style.cssText = "opacity:0";
			_loadingIconActive = false;
		}
	});

}

function detailsOfCar(selectr) {
	window.location.href = "/frames/cars/car-detail.html?sl=" + selectr;
}

function detailsOfChild(selectr) {
	window.location.href = "/frames/child/child-detail.html?sl=" + selectr;
}

function detailsOfPP(selectr) {
	window.location.href = "/frames/paymentplans/pp-overview.html?sl=" + selectr;
}
function detailsOfCrashReport(selectr) {
	window.location.href = "/frames/generic/cr-details.html?id=" + selectr;
}
